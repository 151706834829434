body a {
  outline: none;
  text-decoration: none;
  color: #1890ff;
}

button,
a {
  outline: none !important;
}

.Toastify__toast-body {
  text-transform: capitalize;
}

.bordered {
  border: 1px solid #eee;
}

.bordered-white {
  border: 2px solid white;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.end-0 {
  right: 0;
}

.start-0 {
  left: 0;
}

.text-truncate {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.h-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.bg-grey-dot {
  background-color: #eee;
  background-image: linear-gradient(135deg, #c89118 25%, transparent 25%);
  background-position: 5px 0, 5px 0, 0 0, 0 0;
  background-size: 5px 5px;
  background-repeat: repeat;
}

.bg-card-gray {
  background-color: #fafafa;
}

.m-50p {
  height: 50px;
}

.ant-image-mask {
  font-size: 11px;
  text-align: center;
}

.ant-upload-list-item-progress {
  bottom: 5px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
  border-radius: 5px;
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background-color: #eee;
}
